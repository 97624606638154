<template>
  <div class="page-body">
      <title-bar title="施工登记">
        <el-button type="primary" @click="edit()" icon="fa fa-plus-circle" :loading="loading">
          添加
        </el-button>
        <el-button type="primary" @click="download" :loading="loading" :disabled="!dates">
          导出
        </el-button>
        <el-button type="primary" @click="submitDialog = true" :loading="loading" :disabled="selectedRecords.length === 0">
          批量移交({{selectedRecords.length}})
        </el-button>
      </title-bar>
      <el-form ref="queryForm" v-model="queryForm" inline label-width="80px">
        <div>
          <el-form-item label="工程分类">
            <code-select v-model="queryForm.workType" code-type="workType" @change="query" style="width: 150px"></code-select>
          </el-form-item>
          <el-form-item label="施工班组">
            <code-select v-model="queryForm.team" code-type="team" @change="query" style="width: 150px"></code-select>
          </el-form-item>
          <el-form-item label="上门师傅">
            <code-select v-model="queryForm.worker" code-type="worker" @change="query" style="width: 150px"></code-select>
          </el-form-item>
          <el-form-item label="移交日期">
            <el-date-picker v-model="dates" type="daterange" value-format="yyyy-MM-dd"></el-date-picker>
          </el-form-item>

        </div>
        <div>
          <el-form-item label="客户名称">
            <el-input v-model.trim="queryForm.userName" clearable placeholder="编码或名称模糊匹配" style="width: 150px"/>
          </el-form-item>
          <el-form-item label="地址">
            <el-input v-model.trim="queryForm.address" clearable placeholder="模糊匹配" style="width: 150px"/>
          </el-form-item>
          <el-form-item label="表号">
            <el-input v-model.trim="queryForm.meterNo" clearable placeholder="模糊匹配" style="width: 150px"/>
          </el-form-item>
          <el-form-item label="工单编号">
            <el-input v-model.trim="queryForm.requirementId" clearable placeholder="模糊匹配" style="width: 180px"/>
          </el-form-item>
          <el-form-item label-width="0px">
            <el-button type="primary" @click="query()" icon="fa fa-search" :loading="loading">
              查询
            </el-button>
            <el-button type="default" @click="reset()" :loading="loading">
              重置
            </el-button>
          </el-form-item>
        </div>
      </el-form>
      <el-table ref="tab" stripe :data="records" :border="true" v-loading="loading"
                row-key="id"
                @selection-change="selectChange">
        <el-table-column type="selection" width="40" align="center"
                         :reserve-selection="true"
                         :selectable="(row,index)=>{return !row.submitDate}"/>
        <el-table-column type="index" label="序号" width="50" align="center"/>
        <el-table-column prop="submitDate" label="移交日期" width="90" align="center"/>
        <el-table-column prop="requirementId" label="工单编号" width="140" align="center"/>
        <el-table-column prop="workTypeName" label="工程分类" min-width="80" align="center"/>
        <el-table-column prop="userCode" label="客户" align="center" width="100" v-slot="{row}">
          <div>{{row.userCode}}</div>
          <div>{{row.userName}}</div>
        </el-table-column>
        <el-table-column prop="userPhoneNum" label="电话号码" align="center" width="100" v-slot="{row}">
          <span>{{row.userPhoneNum}}</span>
        </el-table-column>
        <el-table-column prop="address" label="客户地址" min-width="150" v-slot="{row}">
          <el-tooltip :content="row.address" :open-delay="500" :hide-after="100">
            <span>{{row.address}}</span>
          </el-tooltip>
        </el-table-column>
        <el-table-column prop="workDate" label="上门时间" width="90" align="center"/>
        <el-table-column prop="teamName" label="班组" align="center" width="70"/>
        <el-table-column prop="worker" label="师傅" align="center" width="70"/>
        <el-table-column prop="meterNo" label="燃气表号(挂表)" align="center" width="150" v-slot="{row}">
          <div v-for="meter in row.meters" :key="meter.id">
            {{meter.meterNo}}
            <i class="fa fa-check-circle color-success" v-if="meter.hangup"></i>
          </div>
        </el-table-column>
        <el-table-column prop="creatorName" label="登记人" width="65" align="center"/>
        <el-table-column prop="createTime" label="登记时间" width="90" align="center"/>
        <el-table-column prop="edit" label="操作" align="center" width="90" v-slot="{row}" fixed="right">
          <el-link type="primary" @click="edit(row)">修改</el-link>
          <el-link type="danger" @click="del(row)">删除</el-link>
        </el-table-column>
      </el-table>
      <table-pagination :page-query="queryForm" :total="total" @change="loadData"></table-pagination>

      <el-dialog title="移交" :visible.sync="submitDialog" width="300px">
        <el-form inline>
          <el-form-item>
            <el-date-picker v-model="submitDate" value-format="yyyy-MM-dd" :clearable="false"></el-date-picker>
          </el-form-item>
          <div class="button-container">
            <el-button type="primary" @click="doSubmit">移交</el-button>
            <el-button type="default" @click="submitDialog = false">取消</el-button>
          </div>
        </el-form>
      </el-dialog>
    </div>
</template>

<script>
import BaseVue from "@/components/base/BaseVue";
import TitleBar from "@/components/base/TitleBar.vue";
import TablePagination from "@/components/base/TablePagination.vue";
import CodeSelect from "@/components/base/CodeSelect.vue";
import * as querystring from "querystringify"
import Store from "@/utils/Store";
export default {
  components: {CodeSelect, TablePagination, TitleBar},
  extends: BaseVue,
  data() {
    return {
      storeKey: "work-list-query-form",
      records: [],
      selectedRecords: [],
      dates: null,
      submitDialog: false,
      submitDate: this.formatDate(new Date()),
      total: 0,
      queryForm: {
        startDate: null,
        endDate: null,
        workType: null,
        team: null,
        requirementId: null,
        userCode: null,
        userName: null,
        address: null,
        state: null,
        page: 1,
        limit: 10
      }
    }
  },
  created() {
    const param = Store.getObject(this.storeKey);
    if (param) {
      this.queryForm = param;
      Store.clear(this.storeKey);
    }
    this.query();
  },
  methods: {
    loadData() {
      this.loading = true;
      if(this.dates) {
        this.queryForm.startDate = this.dates[0];
        this.queryForm.endDate = this.dates[1];
      }else{
        this.queryForm.startDate = null;
        this.queryForm.endDate = null;
      }
      this.postForm("/gas-work/list", this.queryForm, true).then((dr) => {
        this.records = dr.rows || [];
        this.total = dr.total || 0;
      }).finally(() => {
        this.loading = false;
      });
    },
    query() {
      this.queryForm.page = 1;
      this.loadData();
    },
    //保存查询缓存
    saveCache() {
      Store.setObject(this.storeKey, this.queryForm);
    },
    edit(row) {
      this.saveCache();
      this.$router.push({
        path: "/work-edit",
        query: {
          id: row ? row.id : ""
        }
      })
    },
    del(row){
      this.$confirm("确定要删除此施工单吗？", "提示", {
        type: "warning"
      }).then(()=>{
        this.loading = true;
        this.postForm("/gas-work/delete", {
          id: row.id
        }, true).then(dr=>{
          this.loadData();
        }).finally(dr=>{
          this.loading = false;
        });
      })
    },
    onUploadSuccess(dr) {
      this.loading = false;
      if(dr.success) {
        this.query();
      } else {
        this.$message.error(dr.msg || '导入失败');
      }
    },
    onUploadError(dr) {
      this.loading = false;
      this.$message.error(dr.msg || "导入文件失败");
    },
    reset() {
      this.queryForm = {
        startDate: null,
        endDate: null,
        workType: null,
        team: null,
        requirementId: null,
        userCode: null,
        userName: null,
        address: null,
        state: null,
        page: 1,
        limit: 10
      };
      this.query();
    },
    doSubmit(){
      this.submitDialog = false;
      this.$confirm("确定要移交选中的"+this.selectedRecords.length+"条施工单吗？", "提示", {
        type: "info"
      }).then(()=>{
        let ids = [];
        this.selectedRecords.forEach((r)=>{
          ids.push(r.id);
        });
        this.loading = true;
        this.postForm("/gas-work/submit", {
          ids: ids,
          submitDate: this.submitDate
        }, true).then((dr)=>{
          this.$refs.tab.clearSelection();
          this.selectedRecords = [];
          this.query();
        }).finally(()=>{
          this.loading = false;
        })
      });
    },
    selectChange(selection) {
      this.selectedRecords = selection;
    },
    download(){
      if(!this.dates) {
        this.$message("请选择移交日期");
        return;
      }
      if(this.dates) {
        this.queryForm.startDate = this.dates[0];
        this.queryForm.endDate = this.dates[1];
      }
      window.open(this.globalConfig.apiRoot+"/gas-work/download?"
          + querystring.stringify(this.queryForm));
    }
  }
}
</script>

<style scoped lang="scss">
</style>
